/**
 * Copy the values of all of the enumerable properties from a source object to a target object,
 * only if the source values match the types of the corresponding target values.
 * @param target The target object to copy to.
 * @param source The source object to copy from.
 * @returns The target object.
 */
export function matchedAssign <T extends {}, U extends Partial<T>> (target: T, source: U): T {
	if (!source || typeof source !== 'object') return target;
	for (const key in target) {
		if (typeof source[key] === typeof target[key]) {
			// @ts-ignore: I don't know how to fix this yet.
			target[key as string] = source[key];
		}
	}
	return target;
}
