import { ref } from 'vue';
import { defineStore } from 'pinia';

export const useUserStore = defineStore('user', () => {
	const adminMode = ref(false);

	function reset (): void {
		adminMode.value = false;
	}

	return {
		adminMode,
		reset
	};
});
