import { computed, ref, watch, type WatchStopHandle } from 'vue';
import { defineStore } from 'pinia';
import { Position } from 'monaco-editor';
import { Project, projectDefaults } from '@/structures';
import { Operation } from '@/modules/firebase/operations';
import Firebase from '../firebase/core';

export const useOnlineProjectStore = defineStore('onlineProject', () => {
	const canvasRatio    = ref(projectDefaults.canvasRatio);
	const color          = ref(projectDefaults.backgroundColor);
	const css            = ref(projectDefaults.css);
	const editorRatio    = ref(projectDefaults.editorRatio);
	const flavour        = ref(projectDefaults.flavour);
	const id             = ref('');
	const opacity        = ref(projectDefaults.backgroundAlpha);
	const title          = ref(projectDefaults.title);
	const zoom           = ref(projectDefaults.zoom);
	const cursorPosition = ref(new Position(1, 0));
	const saved          = ref(true);

	const backgroundColor = computed((): string => {
		if (!color.value || !opacity.value) return 'unset';
		const hexAlpha = (opacity.value * 255).toString(16).split('.')[0].padStart(2, '0');
		return `${color.value}${hexAlpha}`;
	});

	const focusedSelector = computed((): string => {
		const above = css.value.split('\n').slice(0, cursorPosition.value.lineNumber).join('\n');
		const pattern = /(?<selector>[^{}]+){[^}]*}?/ig;
		const matches = [...above.matchAll(pattern)];
		const selector = matches.at(-1)?.groups?.selector?.trim();
		return selector ?? '';
	});

	const watchStopHandles = new Array<WatchStopHandle>();

	function startWatchers (): void {
		watchStopHandles.push(
			watch(canvasRatio, () => Operation.update(Operation.Type.Project, id.value, { canvasRatio: canvasRatio.value })),
			watch(color,       () => Operation.update(Operation.Type.Project, id.value, { backgroundColor: color.value })),
			watch(css,         () => Operation.update(Operation.Type.Project, id.value, { css: css.value })),
			watch(editorRatio, () => Operation.update(Operation.Type.Project, id.value, { editorRatio: editorRatio.value })),
			watch(flavour,     () => Operation.update(Operation.Type.Project, id.value, { flavour: flavour.value })),
			watch(opacity,     () => Operation.update(Operation.Type.Project, id.value, { backgroundAlpha: Number(opacity.value) })),
			watch(title,       () => Operation.update(Operation.Type.Project, id.value, { title: title.value })),
			watch(zoom,        () => Operation.update(Operation.Type.Project, id.value, { zoom: Number(zoom.value) }))
		);
	}

	function stopWatchers (): void {
		let watchStopHandle = watchStopHandles.pop();
		while (watchStopHandle) {
			watchStopHandle();
			watchStopHandle = watchStopHandles.pop();
		}
	}

	function load (project: Project): void {
		stopWatchers();
		canvasRatio.value = project.canvasRatio;
		color.value = project.backgroundColor;
		css.value = project.css;
		editorRatio.value = project.editorRatio;
		flavour.value = project.flavour;
		opacity.value = project.backgroundAlpha;
		title.value = project.title;
		zoom.value = project.zoom;
		cursorPosition.value = new Position(0, 0);
		saved.value = true;
		startWatchers();
	}

	function reset (): void {
		id.value = '';
	}

	function select (projectId: string): void {
		id.value = projectId;
	}

	watch(id, () => {
		const project = Firebase.projects.value.get(id.value);
		if (!project) {
			load(projectDefaults);
			return;
		}
		load(project);
	});
	startWatchers();

	return {
		backgroundColor,
		canvasRatio,
		color,
		css,
		editorRatio,
		flavour,
		id,
		opacity,
		reset,
		select,
		title,
		zoom,
		cursorPosition,
		focusedSelector,
		saved
	};
});
