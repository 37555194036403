import type { Router } from 'vue-router';
import { applyActionCode } from 'firebase/auth';
import Firebase from './core';
import { dialogStore } from '@/main';
import { awaitChange } from '@/utilities';

export interface ActionConfig {
	apiKey?: string;
	continueUrl?: string;
	language?: string;
	mode: (string & {}) | 'recoverEmail' | 'resetPassword' | 'verifyEmail';
	oobCode: string;
	router: Router;
}

function removeActionParams (action: ActionConfig): void {
	const query = { ...action.router.currentRoute.value.query };
	delete query['apiKey'];
	delete query['continueUrl'];
	delete query['lang'];
	delete query['mode'];
	delete query['oobCode'];
	action.router.replace({ query });
}

/**
 * Perform a special authentication action.
 * @param action The action to perform.
 * @returns `true` if the action was successful, `false` if not.
 */
export async function action (action: ActionConfig): Promise<boolean> {
	if (!action.mode || !action.oobCode) return false;
	switch (action.mode) {
		case 'resetPassword': {
			removeActionParams(action);
			return false;
		}
		case 'recoverEmail': {
			removeActionParams(action);
			return false;
		}
		case 'verifyEmail': {
			removeActionParams(action);
			try {
				await applyActionCode(Firebase.auth, action.oobCode);
				dialogStore.open(dialogStore.Dialog.Notification, 'Email verified.');
				return true;
			} catch {
				await awaitChange(Firebase.user.raw, true);
				if (Firebase.user.raw.value?.emailVerified) return true;
				dialogStore.open(dialogStore.Dialog.Notification, 'Failed to verify. Please try again in a few minutes.');
				return false;
			}
		}
		default:
			return false;
	}
}
