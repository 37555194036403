<script setup lang="ts">
import { onMounted, ref } from 'vue';

defineProps({
	modelValue: { type: Boolean, required: true },
	horizontal: { type: Boolean, required: false }
});

const emit = defineEmits(['update:modelValue']);
const element = ref<HTMLDivElement | null>(null);
let observer: IntersectionObserver;

onMounted(() => {
	if (!element.value) return;
	observer = new IntersectionObserver(
		(entries) => {
			emit('update:modelValue', Boolean(entries[0]?.isIntersecting));
		},
		{
			root: null,
			threshold: 0
		}
	);
	observer.observe(element.value);
});
</script>

<template>
	<div ref="element" class="paginationTrigger" :class="{ horizontal }"></div>
</template>

<style scoped>
.paginationTrigger {
	display: block;
	width: 100%;
	height: 40rem;
	margin-top: -40rem;
	opacity: 0;
	user-select: none;
	pointer-events: none;
}
.paginationTrigger.horizontal {
	width: 40rem;
	height: 100%;
	margin-top: 0;
	margin-left: -40rem;
}
</style>
