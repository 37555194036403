import { watch, type WatchSource } from 'vue';

/**
 * Wait for the truthy-ness of a reactive source to change.
 * @param source The reactive value.
 * @param value *(Optional)* The value to wait for.
 */
export function awaitChange (source: WatchSource, value?: boolean): Promise<void> {
	return new Promise<void>((resolve) => {
		const initial = typeof source === 'function' ? Boolean(source()) : Boolean(source.value);
		if (initial === value) return resolve();
		const unwatch = watch(source, (newValue) => {
			if (typeof value === 'boolean') {
				if (Boolean(newValue) === value) {
					unwatch();
					resolve();
				}
			} else {
				if (Boolean(newValue) !== initial) {
					unwatch();
					resolve();
				}
			}
		});
	});
}

export function formatDate (date: number): string {
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	const newDate = new Date(date);
	return `${months[newDate.getMonth()]} ${newDate.getUTCDate()}, ${newDate.getFullYear()}`;
}

export function formatDateIso (date: number): string {
	return new Date(date).toISOString();
}

/**
 * Creates a promise that waits for a given delay (in ms) before resolving.
 * @param delay
 */
export function wait (delay: number): Promise<void> {
	return new Promise<void>((resolve) => window.setTimeout(resolve, delay));
}
