<template>
	<div class="premiumBadge">
		<slot></slot>
		<span>Premium ✨</span>
	</div>
</template>

<style scoped>
.premiumBadge {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: fit-content;
	padding: 0.2rem 0.4rem;
	color: white;
	font-size: 0.8rem;
	background-color: var(--color-premium);
	border-radius: var(--border-radius);
}
</style>
