<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import type { Project } from '@/structures';
import DialogBox from '@/components/DialogBox.vue';
import ToggleInput from '@/components/ToggleInput.vue';

const props = defineProps({
	modelValue: { type: Boolean, required: true },
	project: { type: String, required: true }
});

const emit = defineEmits(['update:modelValue']);
const projectData = ref<Project | undefined>(undefined);
const projectLink = ref<string | undefined>(undefined);
const shareLinkCopied = ref(false);
const loading = ref(false);
const loadingVisibility = ref(false);
const shareLink = computed((): string => projectLink.value ? `${window.location.origin}/p/${projectLink.value}` : '');
const facebookLink = computed((): string => `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareLink.value)}`);
const instagramLink = computed((): string => `https://www.instagram.com/`);
const linkedinLink = computed((): string => `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareLink.value)}`);
const twitterLink = computed((): string => `https://x.com/intent/tweet?url=${encodeURIComponent(shareLink.value)}`);
const visibility = computed({
	get: (): boolean => (Boolean(projectData.value?.public)),
	set (value: boolean) {
		if (projectData.value) {
			projectData.value.public = value;
			(value ? void publish() : void unpublish())
		}
	}
});

async function load (): Promise<void> {
	if (loading.value) return;
	const operations = await Promise.all([
		Firebase.publicProjects.get(props.project),
		Firebase.getProjectLink(props.project)
	]);
	projectData.value = operations[0];
	projectLink.value = operations[1];
	loading.value = false;
}
async function publish (): Promise<void> {
	if (loadingVisibility.value) return;
	loadingVisibility.value = true;
	await RPC(RPC.Endpoint.publishProject, { projectId: props.project });
	await load();
	loadingVisibility.value = false;
}
async function unpublish (): Promise<void> {
	if (loadingVisibility.value) return;
	loadingVisibility.value = true;
	await RPC(RPC.Endpoint.unpublishProject, { projectId: props.project });
	await load();
	loadingVisibility.value = false;
}
function copyShareLink (): void {
	navigator.clipboard.writeText(shareLink.value);
	shareLinkCopied.value = true;
}

void load();
watch(() => props.modelValue, (value: boolean) => { value && void load() });
watch(() => props.project, (value: string) => { value && void load() });
</script>

<template>
	<DialogBox
		title="Share"
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="ShareMenu"
	>
		<form @submit.prevent="">
			<fieldset class="link">
				<input type="text" style="width: 100%;border: 1px solid var(--color-border-light);border-radius: var(--border-radius);" :value="shareLink" readonly>
				<div
					class="options"
					:style="{
						opacity: shareLink && visibility && !loadingVisibility ? '1' : '0.5',
						pointerEvents: shareLink && visibility && !loadingVisibility ? 'all' : 'none'
					}"
				>
					<button
						title="Copy link"
						style="width: 100%;"
						@click="copyShareLink()"
						data-journey-id="ShareMenuCopy"
					>
						<span v-if="loading" class="spinner"></span>
						<span v-else class="icon">{{ shareLinkCopied ? 'done' : 'content_copy'}}</span>
						{{ shareLinkCopied ? 'Copied' : 'Copy' }}
					</button>
					<RouterLink
						v-if="shareLink && visibility && !loadingVisibility"
						:to="`/p/${projectLink}`"
						class="button"
						style="width: 100%;"
						data-journey-id="ShareMenuView"
					>
						<span class="icon">panorama</span>
						View
					</RouterLink>
				</div>
			</fieldset>
			<fieldset
				v-if="shareLink"
				class="options socials"
			>
				<a
					:href="facebookLink"
					target="_blank"
					rel="noreferrer"
					class="button facebook"
					title="Share on Facebook"
				>
					<img src="https://cdn.simpleicons.org/facebook/white" width="32" height="32" alt="">
				</a>
				<a
					:href="instagramLink"
					target="_blank"
					rel="noreferrer"
					class="button instagram"
					title="Share on Instagram"
				>
					<img src="https://cdn.simpleicons.org/instagram/white" width="32" height="32" alt="">
				</a>
				<a
					:href="twitterLink"
					target="_blank"
					rel="noreferrer"
					class="button twitter"
					title="Share on Twitter"
				>
					<img src="https://cdn.simpleicons.org/twitter/white" width="32" height="32" alt="">
				</a>
				<a
					:href="linkedinLink"
					target="_blank"
					rel="noreferrer"
					class="button linkedin"
					title="Share on LinkedIn"
				>
					<img src="https://cdn.simpleicons.org/linkedin/white" width="32" height="32" alt="">
				</a>
			</fieldset>
			<template v-if="Firebase.user.uid.value && projectData?.owner === Firebase.user.uid.value">
				<p>Settings</p>
				<fieldset>
					<label
						for="projectSettingsVisibility"
						:style="{
							opacity: loadingVisibility ? '0.5' : '1',
							pointerEvents: loadingVisibility ? 'none' : 'all'
						}"
					>Visibility
						<div style="display: flex;flex-flow: row nowrap;gap: 2ch;">
							<ToggleInput
								name="projectSettingsVisibility"
								checkedIcon="public"
								uncheckedIcon="lock"
								v-model="visibility"
							/>
							<span v-if="loadingVisibility" class="spinner"></span>
							<span>{{ visibility ? 'Public' : 'Private' }}</span>
						</div>
					</label>
				</fieldset>
			</template>
		</form>
	</DialogBox>
</template>

<style scoped>
form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
form fieldset {
	padding: 1ch;
	background-color: var(--color-border-light);
	border: 1px solid var(--color-border-light);
	border-radius: var(--border-radius);
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}
form > p {
	color: grey;
	margin-bottom: -1rem;
}
form > .link {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1ch;
}
form .options {
	display: flex;
	flex-flow: row nowrap;
	gap: 1ch;
	transition: 0.2s ease opacity;
}
form .socials > a {
	width: 100%;
	transition: 0.1s ease filter;
}
form .socials > a:hover {
	filter: brightness(1.25);
}
form .socials > a.facebook {
	color: white;
	background-color: #0866FF;
}
form .socials > a.facebook:hover {
	background-color: #0866FF;
}
form .socials > a.instagram {
	color: white;
	background-color: #E4405F;
}
form .socials > a.instagram:hover {
	background-color: #E4405F;
}
form .socials > a.twitter {
	color: white;
	background-color: #1D9BF0;
}
form .socials > a.twitter:hover {
	background-color: #1D9BF0;
}
form .socials > a.linkedin {
	color: white;
	background-color: #0A66C2;
}
form .socials > a.linkedin:hover {
	background-color: #0A66C2;
}
</style>
