import { md5 } from './md5';
import Firebase from '@/modules/firebase/core';

export function gravatar (size?: number): string {
	if (!Firebase.user.raw.value) {
		return `https://www.gravatar.com/avatar/00000000000000000000000000000000.jpg?s=${size || 28}&d=mp`
	}
	const hash = md5(Firebase.user.raw.value.email?.trim().toLowerCase() ?? '');
	return `https://www.gravatar.com/avatar/${hash}.jpg?s=${size || 28}&d=mp`;
}

export function gravatarFromHash (hash: string, size?: number): string {
	return `https://www.gravatar.com/avatar/${hash}.jpg?s=${size || 28}&d=mp`;
}
