/**
 * The default structure of the response returned from an RPC.
 */
export interface DefaultRPCResponse {
	status: number;
	success: boolean;
	message?: string;
}

/**
 * A factory function that creates a standardised RPC response object.
 */
function response (status: number, success: boolean, message?: string): DefaultRPCResponse {
	const response: DefaultRPCResponse = { status, success };
	if (typeof message === 'string') response.message = message;
	return response;
}

/**
 * A standardised RPC response.
 */
export const RPCResponse = {
	Custom:                      response,
	Success:                     (message?: string) => response(200, true, message),
	BadRequest:                  (message?: string) => response(400, false, message),
	SubscriptionRequired:        (message?: string) => response(402, false, message),
	AccountRequired:             (message?: string) => response(403, false, message),
	NotFound:                    (message?: string) => response(404, false, message),
	ResourceStateConflict:       (message?: string) => response(409, false, message),
	TooLong:                     (message?: string) => response(413, false, message),
	AccountVerificationRequired: (message?: string) => response(449, false, message),
	ServerError:                 (message?: string) => response(500, false, message),
	Unavailable:                 (message?: string) => response(503, false, message)
} as const;
