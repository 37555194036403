<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { theme, userStore } from '@/main';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import { wall } from '@/modules/firebase/walls';
import { gravatar } from '@/modules/gravatar';
import router from '@/router';
import { Theme } from '@/structures';
import DialogBox from '@/components/DialogBox.vue';
import ToggleInput from '@/components/ToggleInput.vue';
import PremiumCard from '@/components/PremiumCard.vue';

const props = defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue', 'delete', 'reset', 'success', 'verify']);
const displayName = ref('');
const displayNameLoading = ref(false);
const displayNameSaved = ref(false);
const profileLink = ref('');
const profileLinkLoading = ref(false);
const profileLinkSaved = ref(false);
const profileLinkError = ref(false);
const formattedProfileLink = computed({
	get: () => profileLink.value.replace(/@+/, ''),
	set: value => profileLink.value = `@${value}`.replace(/@+/, '@')
});
const loading = ref(false);

function hide (): void {
	emit('update:modelValue', false);
}
async function load (): Promise<void> {
	profileLinkLoading.value = true;
	displayName.value = Firebase.user.displayName.value ?? '';
	profileLink.value = Firebase.user.vanityLink.value ?? '';
	profileLinkError.value = false;
	profileLinkLoading.value = false;
}
async function saveDisplayName(): Promise<void> {
	displayNameLoading.value = true;
	displayNameSaved.value = true;
	await Firebase.updateDisplayName(displayName.value);
	displayNameLoading.value = false;
	window.setTimeout(() => {
		displayNameSaved.value = false;
	}, 3000);
}
async function saveProfileLink(): Promise<void> {
	if (!Firebase.user.subscribed.value) return;
	profileLinkLoading.value = true;
	profileLinkSaved.value = true;
	const response = await RPC(RPC.Endpoint.updateProfileLink, { link: profileLink.value });
	if (!response.success) {
		profileLinkError.value = true;
		profileLinkLoading.value = false;
		profileLinkSaved.value = false;
		return;
	}
	profileLinkLoading.value = false;
	window.setTimeout(() => {
		profileLinkSaved.value = false;
	}, 3000);
}

watch(() => props.modelValue, (value) => value && void load());
watch(Firebase.user.link, (value) => value && void load());
watch(profileLink, () => { profileLinkError.value = false; });
watch(Firebase.user.authenticated, (value) => {
	value ? void load() : router.push({ name: 'login' });
});

onMounted(() => void load()); // Load account content when the menu page is navigated to from another page.
</script>

<template>
	<DialogBox
		title="Account"
		wide
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="AccountMenu"
	>
		<template v-slot:default>
			<div style="display: flex;flex-flow: row wrap;justify-content: space-evenly;gap: 1rem;">
				<form @submit.prevent="">
					<div style="display: flex;flex-flow: row wrap;gap: 1rem;">
						<div class="avatar">
							<img :src="gravatar(120)" width="120" height="120" style="border-radius: var(--border-radius);" alt="Profile picture">
							<a href="http://en.gravatar.com/emails/" target="_blank" class="button" title="Change your profile picture">Change</a>
						</div>
						<label for="siteTheme">Theme
							<div style="display: flex;flex-flow: row nowrap;gap: 2ch;">
								<ToggleInput
									name="siteTheme"
									:title="theme.isDark ? 'Change to light mode' : 'Change to dark mode'"
									:checkedIcon="'dark_mode'"
									:uncheckedIcon="'light_mode'"
									:options="[Theme.Light, Theme.Dark]"
									v-model="theme.current"
									data-journey-id="AccountMenuThemeToggle"
								/>
								<span>{{ theme.isDark ? 'Dark' : 'Light' }}</span>
							</div>
						</label>
					</div>
					<label v-if="wall.admin()" for="adminMode">Admin Mode
						<div style="display: flex;flex-flow: row nowrap;gap: 2ch;">
							<ToggleInput
								name="adminMode"
								:title="userStore.adminMode ? 'Disable admin mode' : 'Enable admin mode'"
								danger
								v-model="userStore.adminMode"
								data-journey-id="AccountMenuAdminToggle"
							/>
							<span>{{ userStore.adminMode ? 'Enabled' : 'Disabled' }}</span>
						</div>
					</label>
					<label for="accountDisplayName">Display Name
						<div style="display: flex;flex-flow: row nowrap;gap: 1ch;">
							<input
								type="text"
								id="accountDisplayName"
								name="accountDisplayName"
								maxlength="64"
								autocomplete="nickname"
								v-model="displayName"
							>
							<button
								title="Save"
								:disabled="displayNameLoading || displayNameSaved"
								@click.prevent="saveDisplayName()"
							>
								<span v-if="displayNameLoading" class="spinner" style="font-size: 1em;"></span>
								<span v-else class="icon">{{ displayNameSaved ? 'done' : 'save'}}</span>
							</button>
						</div>
					</label>
					<label
						for="accountProfileLink"
						:class="{ disable: !Firebase.user.subscribed.value }"
						:title="!Firebase.user.subscribed.value ? 'Subscribe to change your profile link' : ''"
					>Custom Profile Link
						<span class="profileLinkPreview">cascades.app/@{{ formattedProfileLink }}</span>
						<div class="profileLink">
							<input
								type="text"
								id="accountProfileLink"
								name="accountProfileLink"
								:style="{ border: profileLinkError ? '1px solid var(--color-danger)' : '' }"
								:readonly="!Firebase.user.subscribed.value"
								maxlength="40"
								:autocomplete="!Firebase.user.subscribed.value ? 'off' : 'nickname'"
								v-model="formattedProfileLink"
							>
							<button
								title="Save"
								:disabled="profileLinkLoading || profileLinkSaved || !Firebase.user.subscribed.value"
								@click.prevent="saveProfileLink()"
							>
								<span v-if="profileLinkLoading" class="spinner" style="font-size: 1em;"></span>
								<span v-else class="icon">{{ profileLinkSaved ? 'done' : 'save'}}</span>
							</button>
						</div>
						<span v-if="profileLinkError" style="color: var(--color-danger);font-size: x-small;">Sorry, that link is unavailable.</span>
					</label>
					<label for="accountEmail">
						<div class="row">
							<span>Email</span>
							<div
								v-if="Firebase.user.raw.value?.emailVerified"
								class="row"
								style="gap: 0.5ch;color: var(--color-success);"
							>
								<span class="icon">done</span>
								<span style="font-size: small;">Verified</span>
							</div>
							<button
								v-else
								style="font-size: small;"
								:disabled="loading"
								@click.prevent="{ emit('verify'); hide(); }"
								data-journey-id="AccountMenuResetPassword"
							>Verify</button>
						</div>
						<input type="email" id="accountEmail" name="accountEmail" :value="Firebase.user.raw.value?.email ?? ''" readonly>
					</label>
					<button
						:disabled="loading"
						@click.prevent="{ emit('reset'); hide(); }"
						data-journey-id="AccountMenuResetPassword"
					>Reset Password</button>
					<button
						:disabled="loading"
						@click.prevent="{ Firebase.logout(); hide(); }"
						data-journey-id="AccountMenuLogout"
					>
						<span v-if="loading" class="spinner"></span>
						<template v-else>Logout</template>
					</button>
				</form>

				<PremiumCard />
			</div>
		</template>
		<template v-slot:bottom>
			<p>No longer want this service?</p>
			<button class="danger" :disabled="loading" @click.stop="{ emit('delete'); hide(); }" data-journey-id="AccountMenuDelete">Delete account</button>
		</template>
	</DialogBox>
</template>

<style scoped>
form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}
form label.disable {
	opacity: 0.5;
	user-select: none;
}
form label.disable > * {
	pointer-events: none;
}
form label .row {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 2ch;
}
form select {
	width: 100%;
	padding: 0.25em;
	font-size: 1rem;
	border: 1px solid var(--color-border);
	border-radius: var(--border-radius);
	transition: 0.1s ease background-color;
}

.avatar {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: fit-content;
}
.avatar > img {
	opacity: 1;
	transition: 0.1s ease opacity;
}
.avatar:hover > img {
	opacity: 0.1;
}
.avatar > a {
	position: absolute;
	opacity: 0;
	transition: 0.1s ease opacity;
}
.avatar:hover > a {
	opacity: 1;
}

.profileLinkPreview {
	max-width: 29ch;
	font-size: small;
	text-overflow: ellipsis;
	white-space: nowrap;
	opacity: 0.5;
	overflow: hidden;
}

.profileLink {
	display: flex;
	flex-flow: row nowrap;
	gap: 1ch;
}
</style>
