import type { DataConverterRX, DataConverterTX } from './meta';
import { matchedAssign } from './other';
import { SubscriptionTier } from './subscription';
import { Theme } from './theme';

export class AccountMetadata {
	likes = 0;
	live = false;
	pinnedProject = '';
	views = 0;

	static fromFirestore: DataConverterRX<AccountMetadata> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new AccountMetadata(), data);
	}

	static toFirestore: DataConverterTX<AccountMetadata> = (metadata) => {
		return { ...metadata };
	}
}

export class AccountSettings {
	biography = '';
	liked = new Array<string>();
	role = Role.None;
	theme = Theme.Light;

	static fromFirestore: DataConverterRX<AccountSettings> = (snapshot) => {
		const data = snapshot.data();
		return matchedAssign(new AccountSettings(), data);
	}

	static toFirestore: DataConverterTX<AccountSettings> = (settings) => {
		return { ...settings };
	}
}

/**
 * The information about a user that is publicly available.
 */
export interface PublicUserInfo {
	biography: string | undefined;
	displayName: string | undefined;
	likes: number;
	live: boolean | string;
	pinnedProject: string | undefined;
	profilePic: string | undefined;
	role: Role;
	tier: SubscriptionTier;
}

export enum Role {
	None,
	Elevated,
	Moderator,
	Administrator
}
