<script setup lang="ts">
import { ref, watch } from 'vue';
import { flagsStore, theme } from '@/main';
import Firebase from '@/modules/firebase/core';
import { Theme } from '@/structures';
import AccountButton from '@/components/AccountButton.vue';
import LandingSplash from '@/components/LandingSplash.vue';
import LandingShowcase from '@/components/LandingShowcase.vue';
import PremiumCard from '@/components/PremiumCard.vue';
import ProjectCard from '@/components/ProjectCard.vue';
import RoadmapSection from '@/components/RoadmapSection.vue';
import TextLogo from '@/components/TextLogo.vue';
import ToggleInput from '@/components/ToggleInput.vue';

const loading = ref(false);
const featuredProjects = ref(['', '', '', '', '', '']);
const skeletonProjects = ['', '', '', '', '', ''];

async function load (): Promise<void> {
	if (!flagsStore.features) return;
	if (loading.value) return;
	loading.value = true;
	featuredProjects.value = await Firebase.getFeaturedProjects(true);
	loading.value = false;
}
void load();
watch(() => Firebase.config.value?.featureDate, load);
watch(() => flagsStore.features, load);
</script>

<template>
	<main>
		<LandingSplash variant="full-without-trees" />

		<div class="loginAndRegister">
			<ToggleInput
				name="landingColorMode"
				:title="theme.isDark ? 'Change to light mode' : 'Change to dark mode'"
				:checkedIcon="'dark_mode'"
				:uncheckedIcon="'light_mode'"
				:style="{ backgroundColor: theme.isDark ? 'unset' : '#eeea' }"
				:options="[Theme.Light, Theme.Dark]"
				v-model="theme.current"
				data-journey-id="LandingThemeToggle"
			/>
			<AccountButton large light-hover navigate />
		</div>

		<section class="landing">
			<div class="landingInfo">
				<TextLogo />
				<p style="font-size: large;">Create amazing CSS art, without any markup!</p>
				<div class="landingActions">
					<RouterLink to="/create" class="cta" data-journey-id="LandingCreateCTA">
						<span aria-hidden="true">🖌️ </span>Create
					</RouterLink>
					<RouterLink to="/browse" class="cta" data-journey-id="LandingBrowseCTA">
						<span aria-hidden="true">🖼️ </span>Browse
					</RouterLink>
				</div>
			</div>
			<LandingShowcase />
		</section>

		<section class="recent" v-if="flagsStore.features">
			<h2>Recent Creations</h2>
			<p>Top picks from the community this week.</p>
			<ul class="features">
				<template v-if="loading">
					<li v-for="project of skeletonProjects">
						<ProjectCard :project />
					</li>
				</template>
				<template v-else>
					<li v-for="project of featuredProjects">
						<ProjectCard :project />
					</li>
				</template>
			</ul>
			<div class="landingActions">
				<RouterLink
					to="/browse"
					class="cta"
					data-journey-id="LandingRecentsBrowse"
				>Browse More</RouterLink>
			</div>
		</section>

		<section class="premium">
			<h2>Premium</h2>
			<div>
				<PremiumCard noHeading wide />
				<div class="why">
					<h3>Your subscription goes towards:</h3>
					<ul>
						<li>Website hosting.</li>
						<li>Cloud storage.<br>
							<span>Every CSS project costs a few cents to host.</span>
						</li>
						<li>Development time.<br>
							<span>The more time I can dedicate to Cascades, the better it can be 🎉.</span>
						</li>
					</ul>
				</div>
			</div>
		</section>

		<section class="roadmap">
			<h2>Roadmap</h2>
			<p>Follow the development of Cascades.</p>
			<RoadmapSection />
		</section>

		<footer data-journey-id="LandingFooter">
			<a href="https://github.com/Cascades-CSS/" target="_blank" rel="noreferrer">
				<img
					:src="`https://cdn.simpleicons.org/github/${theme.isDark ? 'fff' : '000'}`"
					width="16"
					height="16"
					alt="GitHub logo"
					loading="lazy"
					aria-hidden="true"
				>
				GitHub
			</a>
			<a href="https://discord.gg/9hvS9cKBdW" target="_blank" rel="noreferrer">
				<img
					:src="`https://cdn.simpleicons.org/discord/${theme.isDark ? 'fff' : '000'}`"
					width="16"
					height="16"
					alt="Discord logo"
					loading="lazy"
					aria-hidden="true"
				>
				Discord
			</a>
			<RouterLink to="sitemap">
				<span class="icon" aria-hidden="true">map</span>
				Sitemap
			</RouterLink>
			<RouterLink to="/document/terms-of-service">
				<span class="icon" aria-hidden="true">description</span>
				Terms
			</RouterLink>
			<RouterLink to="/document/privacy-policy">
				<span class="icon" aria-hidden="true">policy</span>
				Privacy Policy
			</RouterLink>
			<a href="mailto:contact@cascades.app" target="_blank" rel="noreferrer">
				<span class="icon" aria-hidden="true">mail</span>
				Contact
			</a>
		</footer>
	</main>
</template>

<style scoped>
main {
	gap: 25rem;
	background: var(--color-background);
}

section.landing {
	display: grid;
	grid-template-columns: 32rem 1fr;
	grid-template-rows: 70%;
	place-content: center;
	place-items: center;
	gap: 2rem;
	min-height: 100vh;
	padding: 0 20px 0 50px;
}

@media screen and (max-width: 110rem) {
	section.landing {
		grid-template-rows: 50%;
	}
}

@media screen and (max-width: 90rem) {
	section.landing {
		grid-template-columns: 80%;
		grid-template-rows: 29rem calc(80% - 29rem);
	}
}

@media screen and (max-width: 68rem) {
	section.landing {
		grid-template-columns: 100%;
		grid-template-rows: 100%;
		padding-left: 20px;
	}
}

.landingInfo {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 2rem;
	filter: drop-shadow(0px 0px 15px #00000018);
}
.landingInfo > p {
	margin: 0;
	text-align: center;
	text-wrap: balance;
	filter: drop-shadow(0px 0px 10px #0004);
}

.landingActions {
	display: flex;
	flex-flow: row wrap;
	gap: 2ch;
}

.cta {
	padding: 0.7ch 1ch 1ch;
	color: white;
	font-size: large;
	line-height: 1.3;
	text-decoration: none;
	background-color: var(--color-primary);
	border-radius: var(--border-radius);
	transition:
		0.1s ease background-color,
		0.1s ease box-shadow;
}
.cta:hover {
	box-shadow: 0 2px 10px -4px #0007;
}
html.dark .cta {
	background-color: var(--color-border-light);
	box-shadow: var(--popout-shadow);
}
html.dark .cta:hover {
	background-color: var(--color-primary);
}

section:is(.recent, .roadmap, .premium) {
	max-width: 80rem;
}

section.recent {
	margin-top: -10rem;
}

.features {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 1rem 3rem;
	min-height: 29rem;
	margin: 0 0 3rem;
	padding: 0;
	list-style: none;
}
@media screen and (min-width: 50rem) {
	.features {
		align-items: flex-start;
	}
}

.loginAndRegister {
	position: absolute;
	z-index: 5;
	top: 2rem;
	right: 1rem;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	gap: 1rem;
}

section.premium {
	padding-bottom: 4vh;
}
section.premium > div {
	display: flex;
	flex-flow: row nowrap;
	gap: 3rem;
}
section.premium .why {
	flex-shrink: 1.4;
	padding: 2rem;
	color: white;
	background-color: var(--color-darkgrey);
	border-radius: var(--border-radius);
	box-shadow: var(--popout-shadow);
}
section.premium .why::before {
	content: '';
	position: absolute;
	inset: 0;
	background-color: #ffffff1a;
	background: radial-gradient(circle at 0 0, #ffffff1a, transparent);
	border: 3px solid #ffffff15;
	border-radius: inherit;
	box-shadow: 0 2px 25px -15px #000c;
	pointer-events: none;
}

section.premium .why ul {
	padding-inline-start: 28px;
}

section.premium .why li {
	margin-bottom: 1.5ch;
}

section.premium .why li span {
	color: lightgrey;
}

@media screen and (max-width: 50rem) {
	section.premium > div {
		flex-wrap: wrap;
	}
	section.premium .why {
		width: 100%;
	}
}

section > h2 {
	text-align: center;
}
section > h2 + p {
	margin-top: -1.5rem;
	margin-bottom: 3rem;
	text-align: center;
	text-wrap: balance;
}

footer {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 1rem 3rem;
	margin-bottom: 1.5rem;
	padding-inline: 2rem;
}

footer > a {
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	gap: 0.7ch;
	text-decoration: none;
}

footer > a img {
	width: 1em;
	height: 1em;
	aspect-ratio: 1;
}
</style>
