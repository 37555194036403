<script setup lang="ts">
import { ref, watch } from 'vue';
import { createUserWithEmailAndPassword } from "firebase/auth";
import Firebase from '@/modules/firebase/core';
import DialogBox from '@/components/DialogBox.vue';

defineProps({ modelValue: { type: Boolean, required: true } });
const emit = defineEmits(['update:modelValue', 'login', 'success', 'verify']);
const displayName = ref('');
const email = ref('');
const password = ref('');
const loading = ref(false);
const error = ref(false);
const errorMessage = ref('');

const hide = (): void => emit('update:modelValue', false);

function parseErrorMessage (code: string, message: string): string {
	if (!code || !message) return '';
	switch (code) {
		case 'auth/invalid-email':
			return 'Invalid email address.';
		case 'auth/weak-password':
			return message.replace('Firebase: ', '').replace(/\s\([a-z0-9\/\-_]+\)/i, '');
		default:
			return '';
	}
}

async function createAccount (): Promise<void> {
	loading.value = true;
	try {
		const credential = await createUserWithEmailAndPassword(Firebase.auth, email.value, password.value);
		if (displayName.value) await Firebase.updateDisplayName(displayName.value);
		loading.value = false;
		emit(credential.user.emailVerified ? 'success' : 'verify');
		hide();
	} catch (e: any) {
		loading.value = false;
		error.value = true;
		errorMessage.value = parseErrorMessage(e.code, e.message);
		console.warn('Failed to create account:', e.code, e.message);
	}
}

watch(email, () => { error.value = false; errorMessage.value = ''; });
watch(password, () => { error.value = false; errorMessage.value = ''; });
</script>

<template>
	<DialogBox
		title="Create an account"
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="RegisterMenu"
	>
		<template v-slot:default>
			<form @submit.prevent="createAccount()">
				<label for="registerEmail">Email
					<input
						type="email"
						id="registerEmail"
						name="registerEmail"
						autocomplete="email"
						required
						v-model="email"
					>
				</label>
				<label for="registerDisplayName">Display Name
					<input
						type="text"
						maxlength="64"
						id="registerDisplayName"
						name="registerDisplayName"
						autocomplete="nickname"
						required
						v-model="displayName"
					>
				</label>
				<label for="registerPassword">Password
					<input
						type="password"
						id="registerPassword"
						name="registerPassword"
						autocomplete="new-password"
						required
						v-model="password"
					>
				</label>
				<p v-if="error && errorMessage" class="errorMessage"><i>{{ errorMessage }}</i></p>
				<p v-else-if="error" class="errorMessage"><i>Something went wrong. Please try again.</i></p>
				<button type="submit" :disabled="loading" data-journey-id="RegisterMenuRegister">
					<span v-if="loading" class="spinner"></span>
					<template v-else>Sign up</template>
				</button>
			</form>
		</template>
		<template v-slot:bottom>
			<p>Have an account?</p>
			<button
				:disabled="loading"
				@click.stop="{ emit('login'); hide(); }"
				data-journey-id="RegisterMenuLogin"
			>Login</button>
		</template>
	</DialogBox>
</template>

<style scoped>
form {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 1.5rem;
}
form label {
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	gap: 0.5ch;
	width: 100%;
}
form input {
	width: 100%;
}
form .errorMessage {
	margin: 0;
	color: var(--color-danger);
	font-size: small;
}
</style>
