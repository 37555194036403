<script setup lang="ts">
import { onMounted } from 'vue';

defineProps({
	modelValue: { type: Boolean, required: true },
	message: { type: String, required: true }
});

const emit = defineEmits(['update:modelValue']);

onMounted(() => {
	setTimeout(() => {
		emit('update:modelValue', false);
	}, 4000);
});
</script>

<template>
	<div class="notification" :class="{ show: modelValue }" data-journey-id="Notification">
		<p>{{ message }}</p>
		<button
			class="close"
			@click="emit('update:modelValue', false)"
			data-journey-id="NotificationClose"
		><span class="icon">close</span></button>
	</div>
</template>

<style scoped>
.notification {
	position: fixed;
	z-index: 100;
	right: 10px;
	bottom: 10px;
	display: none;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	gap: 0.5em;
	padding: 0.7ch;
	color: white;
	background-color: var(--color-darkgrey);
	border-radius: var(--border-radius);
	box-shadow: 0 4px 13px -5px #0006;
	animation: notificationFadeIn 0.5s ease forwards;
	pointer-events: none;
}
.notification.show {
	display: flex;
	pointer-events: all;
}
.notification p {
	margin: 0 0.5em;
}
@keyframes notificationFadeIn {
	0%   { transform: translateY(100%); opacity: 0; }
	100% { transform: translateY(0%); opacity: 1; }
}
</style>
