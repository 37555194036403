<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import Firebase from '@/modules/firebase/core';
import { gravatarFromHash } from '@/modules/gravatar';
import type { Project } from '@/structures';
import { formatDate, formatDateIso } from '@/utilities';
import LikeButton from '@/components/LikeButton.vue';

const props = defineProps({
	large: { type: Boolean, required: false, default: false },
	loading: { type: Boolean, required: false, default: false },
	project: { type: String, required: false },
	search: { type: String, required: false }
});

const projectData = ref<Project | undefined>(undefined);
const ownerName = ref<string | undefined>(undefined);
const ownerPic = ref<string | undefined>(undefined);
const ownerUid = ref('');
const ownerHandle = ref('');
const ownerLink = computed(() => (ownerHandle.value?.startsWith('@') ? `/${ownerHandle.value}` : `/profile/${ownerHandle.value || ownerUid.value}`));
const loadingData = ref(false);
const loadingThumbnail = ref(false);

const searchCondition = computed((): boolean => {
	if (!projectData.value || !props.search) return true;
	return projectData.value.title.toLowerCase().includes(props.search.toLowerCase());
});

async function load (): Promise<void> {
	if (loadingData.value || !props.project) return;
	loadingData.value = true;
	loadingThumbnail.value = true;
	projectData.value = await Firebase.publicProjects.get(props.project);
	if (projectData.value?.owner) {
		ownerUid.value = projectData.value.owner;
		Firebase.longProfileLinks.get(ownerUid.value).then((link) => { ownerHandle.value = link || ''; });
		const owner = await Firebase.publicUsers.get(ownerUid.value);
		ownerName.value = owner?.displayName;
		ownerPic.value = owner?.profilePic;
	}
	loadingData.value = false;
}

void load();
watch(() => props.project, () => void load());
</script>

<template>
	<RouterLink
		v-if="searchCondition"
		:to="`/project/${project}`"
		class="projectCard"
		:class="{ large, loading: loadingData || loadingThumbnail || loading || !project }"
		data-journey-id="ProjectCard"
	>
		<img
			class="preview"
			:src="projectData?.thumbnail ?? ''"
			alt=""
			@load="loadingThumbnail = false"
		>
		<div class="previewOverlay"></div>
		<div class="options" @click.stop.prevent>
			<LikeButton
				:project
				data-journey-id="ProjectCardLike"
			/>
			<RouterLink
				v-if="Firebase.user.uid.value === (projectData?.owner ?? '')"
				class="button"
				title="Edit"
				:to="'/create/' + project"
				data-journey-id="ProjectCardEdit"
			><span class="icon">edit</span></RouterLink>
			<slot name="options"></slot>
		</div>
		<div class="info">
			<h3>{{ projectData?.title }}</h3>
			<p>
				<RouterLink :to="ownerLink" data-journey-id="ProjectCardAuthor">
					<img :src="ownerPic ? gravatarFromHash(ownerPic) : ''" width="20" height="20" alt="">
					{{ ownerName }}
				</RouterLink>
				<time v-if="projectData?.datePublished" :datetime="formatDateIso(projectData.datePublished)"> • {{ formatDate(projectData.datePublished) }}</time>
			</p>
		</div>
	</RouterLink>
</template>

<style scoped>
.projectCard {
	display: grid;
	grid-template-columns: 17rem 2rem;
	grid-template-rows: 10rem 4rem;
	gap: 0.2em;
	width: fit-content;
	margin-top: 1rem;
	color: inherit;
	text-decoration: none;
}
.projectCard.loading {
	pointer-events: none;
}
.projectCard.loading > * {
	opacity: 0.2;
}
.projectCard.loading > *::after {
	content: '';
	position: absolute;
	inset: 0;
	background-color: #888;
	background: linear-gradient(90deg, #888 60%, #bbb 95%, #888);
	background-attachment: fixed;
	background-position: 0 0;
	background-repeat: repeat;
	background-size: 200vmax 200vmax;
	border-radius: inherit;
	animation: loadingPulse 2.5s linear infinite;
}

.projectCard > .preview {
	grid-area: 1 / 1 / 2 / 3;
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	background-color: #888;
	border-radius: var(--border-radius);
	box-shadow: 0 2px 10px -4px #0007;
	transition: 0.2s ease transform;
	overflow: hidden;
}
.projectCard > .previewOverlay {
	position: absolute;
	grid-area: 1 / 1 / 2 / 3;
	width: 100%;
	height: 100%;
	border-radius: var(--border-radius);
	box-shadow: var(--popout-shadow-medium);
	mix-blend-mode: overlay;
	transition: 0.2s ease transform;
	pointer-events: none;
}
.projectCard:not(.loading):hover > :is(.preview, .previewOverlay) {
	transform: translateY(-0.5rem);
}

.projectCard > .options {
	grid-area: 1 / 2 / 2 / 3;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-end;
	gap: 1ch;
	padding: 0.5ch;
	color: white;
	opacity: 0;
	transition: 0.1s ease opacity;

	&::before {
		content: '';
		position: absolute;
		inset: 0 0 0 -2em;
		display: block;
		background: linear-gradient(270deg, #0006, transparent);
		border-radius: 0 var(--border-radius) var(--border-radius) 0;
		transition: 0.2s ease transform;
	}
}
.projectCard.loading > .options {
	display: none;
}
.projectCard:is(:hover, :focus-within) > .options {
	opacity: 1;
}
.projectCard:hover > .options::before {
	transform: translateY(-0.5rem);
}

.projectCard > .info {
	grid-area: 2 / 1 / 3 / 3;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.2em;
	padding: 0.5ch 1ch;
	border-radius: var(--border-radius);
}
.projectCard.loading > .info::after {
	inset: 1ch 0 0 0;
}

.projectCard > .info h3 {
	width: 100%;
	margin: 0;
	font-size: 1rem;
	font-weight: 400;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.projectCard > .info p {
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	gap: 0.5ch;
	width: 100%;
	margin: 0;
	font-size: 0.8rem;
	white-space: nowrap;
	overflow: hidden;
}

.projectCard > .info p > a {
	display: inline-flex;
	flex-flow: row nowrap;
	justify-content: flex-start;
	align-items: center;
	max-width: 23ch;
	padding: 0.2em;
	padding-bottom: 0.3em;
	color: inherit;
	text-decoration: none;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-radius: var(--border-radius);
	outline: unset;
	transition: 0.1s ease background-color;
	overflow: hidden;
}
.projectCard > .info p > a:is(:hover, :focus-visible) {
	background-color: var(--color-button-background);
}
.projectCard.loading > .info p > a {
	opacity: 0;
}

.projectCard > .info p img {
	display: inline-block;
	width: 1.5em;
	height: 1.5em;
	aspect-ratio: 1;
	margin-right: 0.5ch;
	border-radius: 50%;
}

@media screen and (min-width: 50rem) {
	.projectCard.large {
		grid-template-columns: auto 2rem auto;
		grid-template-rows: 3.2rem 6.8rem;
	}
	.projectCard.large > .preview {
		grid-area: 1 / 1 / 3 / 3;
	}
	.projectCard.large > .info {
		grid-area: 1 / 3 / 3 / 4;
		gap: 0.5em;
		font-size: 1rem;
		width: 40ch;
	}
	.projectCard.large > .info h3 {
		content: 'Project Card';
		margin: 0;
		font-size: 1.1rem;
		font-weight: 400;
	}
	.projectCard.large > .info p {
		font-size: 0.85rem;
	}
}

@media screen and (max-width: 28rem) {
	.projectCard {
		grid-template-columns: calc(95vw - 2rem - 20px) 2rem;
		grid-template-rows: 50vw 4rem;
	}
}
</style>
