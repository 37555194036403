<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import HTMLGenerator from '@/modules/editor/generator';
import Firebase from '@/modules/firebase/core';
import { RPC } from '@/modules/firebase/rpc';
import { ImageFormat, type Project } from '@/structures';
import DialogBox from '@/components/DialogBox.vue';
import ToggleInput from '@/components/ToggleInput.vue';

const props = defineProps({
	modelValue: { type: Boolean, required: true },
	project: { type: String, required: true }
});

const emit = defineEmits(['update:modelValue']);
const error = ref(false);
const generator = new HTMLGenerator();
generator.disableAnimations.value = true;
const loading = ref(false);
const options = ref({
	width: 1000,
	height: 1000,
	format: ImageFormat.PNG,
	square: true
});
const preview = ref<HTMLDivElement | null>(null);
const projectData = ref<Project | undefined>(undefined);

const backgroundColor = computed(() => {
	if (!projectData.value) return 'var(--color-background)';
	const alpha = (projectData.value.backgroundAlpha * 255).toString(16).split('.')[0].padStart(2, '0');
	return projectData.value.backgroundColor + alpha;
});

const previewWidth = computed(() => {
	const max = Math.max(options.value.width, options.value.height);
	return `${options.value.width / max * 200}%`;
});
const previewHeight = computed(() => {
	if (options.value.square) return previewWidth.value;
	const max = Math.max(options.value.width, options.value.height);
	return `${options.value.height / max * 200}%`;
});

const height = computed({
	get: () => options.value.square ? options.value.width : options.value.height,
	set: value => options.value.square ? (options.value.width = value) : (options.value.height = value)
});

async function load (): Promise<void> {
	loading.value = true;
	projectData.value = await Firebase.publicProjects.get(props.project);
	if (!preview.value || !projectData.value) return;
	generator.applyShadowDom(preview.value);
	generator.flavour.value = projectData.value.flavour;
	void generator.set(projectData.value.css);
	loading.value = false;
}

async function download (): Promise<void> {
	if (loading.value || !preview.value || !projectData.value) return;
	loading.value = true;
	const response = await RPC(RPC.Endpoint.addImageTask, {
		projectId: props.project,
		format: options.value.format,
		width: options.value.width,
		height: options.value.square ? options.value.width : options.value.height,
	});
	if (!response.success || !response.message) {
		error.value = true;
		loading.value = false;
		return;
	}
	try {
		const url = await Firebase.waitForImageResult(response.message);
		window.open(url, '_self');
		emit('update:modelValue', false);
	} catch {
		error.value = true;
	} finally {
		loading.value = false;
	}
}

void load();
watch(() => props.modelValue, value => value && void load());
watch(() => props.project, value => value && void load());
watch(() => options, () => { error.value = false; }, { deep: true });
</script>

<template>
	<DialogBox
		title="Download Image"
		wide
		:modelValue
		@update:modelValue="emit('update:modelValue', $event)"
		data-journey-id="DownloadImageMenu"
	>
		<form @submit.prevent="download()">
			<div class="preview">
				<div
					ref="preview"
					class="inner"
					:style="{
						width: previewWidth,
						height: previewHeight,
						backgroundColor
					}"
				></div>
			</div>
			<div class="options" :class="{ loading }">
				<label for="downloadImageSquare">
					<span>Square</span>
					<ToggleInput
						class="toggle"
						name="downloadImageSquare"
						v-model="options.square"
					/>
				</label>
				<label for="downloadImageWidth">
					<span>Width</span>
					<input
						type="range"
						name="downloadImageWidth"
						id="downloadImageWidth"
						min="50"
						max="3000"
						step="50"
						:disabled="loading"
						v-model="options.width"
					>
					<input
						type="number"
						name="downloadImageWidth"
						id="downloadImageWidth"
						min="50"
						max="3000"
						step="50"
						:disabled="loading"
						v-model="options.width"
					>
				</label>
				<label for="downloadImageHeight" :class="{ disabled: options.square }">
					<span>Height</span>
					<input
						type="range"
						name="downloadImageHeight"
						id="downloadImageHeight"
						min="50"
						max="3000"
						step="50"
						:disabled="loading || options.square"
						v-model="height"
					>
					<input
						type="number"
						name="downloadImageHeight"
						id="downloadImageHeight"
						min="50"
						max="3000"
						step="50"
						:disabled="loading || options.square"
						v-model="height"
					>
				</label>
				<label for="downloadImageFormat">
					<span>Format</span>
					<select
						name="downloadImageFormat"
						id="downloadImageFormat"
						:disabled="loading"
						v-model="options.format"
					>
						<option :value="ImageFormat.JPG">JPG</option>
						<option :value="ImageFormat.PNG">PNG</option>
						<option :value="ImageFormat.WEBP">WEBP</option>
					</select>
				</label>
				<p
					class="danger"
					style="font-size: small;"
					:style="{
						opacity: error ? '1' : '0',
						userSelect: error ? 'unset' : 'none',
						pointerEvents: error ? 'unset' : 'none'
					}" 
				>Something went wrong. Please try again in a few minutes.</p>
			</div>
			<button type="submit" :disabled="loading" data-journey-id="DownloadImageMenuSubmit">
				<span v-if="loading" class="spinner"></span>
				<template v-else>Download</template>
			</button>
		</form>
	</DialogBox>
</template>

<style scoped>
form {
	display: grid;
	grid-template-columns: 1fr 0.5fr;
	grid-template-rows: 1fr auto;
	grid-template-areas:
		'preview options'
		'preview submit';
	gap: 1rem;
}

@media only screen and (max-width: 50rem) {
	form {
		display: flex;
		flex-flow: column nowrap;
	}
}

.preview {
	grid-area: preview;
	display: grid;
	place-items: center;
	width: 100%;
	height: 100%;
	min-height: 250px;
	aspect-ratio: 1;
	background-color: var(--color-border-light);
	border: 1px solid var(--color-border);
	border-radius: var(--border-radius);
	overflow: hidden;
	pointer-events: none;

	& > .inner {
		display: grid;
		place-items: center;
		width: 200%;
		height: 200%;
		background-color: var(--color-background);
		border: 1px solid var(--color-border-light);
		transform-origin: center;
		transform: scale(0.5);
		overflow: hidden;
	}
}

.options {
	grid-area: options;
	display: flex;
	flex-flow: column nowrap;
	gap: 1ch;

	&.loading {
		opacity: 0.7;
		pointer-events: none;
	}
}

.options > label {
	display: grid;
	grid-template-columns: 1fr 6ch;
	grid-template-rows: auto auto;
	gap: 0 1ch;
	padding: 1ch;
	background-color: var(--color-border-light);
	border-radius: var(--border-radius);

	&.disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	& > span {
		grid-area: 1 / 1 / 2 / 3;
	}

	& > .toggle {
		grid-area: 1 / 2 / 2 / 3;
		justify-self: end;
	}

	& > input:nth-of-type(1) {
		grid-area: 2 / 1 / 3 / 2;
	}

	& > input[type="number"] {
		width: 100%;
		color: var(--color-text);
		background-color: var(--color-background);
		border-radius: var(--border-radius);
	}

	& > select {
		grid-area: 2 / 1 / 3 / 3;
		color: var(--color-text);
		background-color: var(--color-background);
		border-color: transparent;
		border-radius: var(--border-radius);
	}
}

button[type="submit"] {
	grid-area: submit;
}
</style>
