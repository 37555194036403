export const maxAllowedFreeProjects = 3;

export const subscribedStatuses = ['trialing', 'active'];

export interface ProductInfo {
	active: boolean;
	description: string | null;
	name: string;
	role: string;
	prices: {
		[key: string]: PriceInfo;
	};
}

export interface PriceInfo {
	active: boolean;
	billing_scheme: "per_unit";
	currency: "nzd";
	interval: "month" | "year";
	interval_count: number;
	product: string;
	unit_amount: number;
}

export interface PricePlans {
	categories: Map<string, Map<string, PriceInfo>>;
	products: Map<string, ProductInfo>;
}

export enum SubscriptionTier {
	Free,
	Premium
}
